export const mockMetrics = [
  { name: "Total Sales", checked: false },
  { name: "NTB Sales", checked: false },
  { name: "Total Ad Sales", checked: false },
  { name: "Total Ad Spend", checked: false },
  { name: "Subscription Sales", checked: false },
  { name: "Contribution Profit", checked: false },
  { name: "Total Ad Spend", checked: false },
  { name: "Subscription Sales", checked: false },
  { name: "Total Sales", checked: false },
  { name: "NTB Sales", checked: false },
  { name: "Total Ad Sales", checked: false },
  { name: "Contribution Profit", checked: false },
  { name: "Subscription Sales ", checked: false },
  { name: "Total Sales", checked: false },
  { name: "NTB Sales", checked: false },
];
