import { useState } from "react";
import styles from "./styles.module.scss";
import Camera from "@assets/icons/camera";
import { useUserStore } from "src/store/user.state";

export const Welcome = () => {
  const [showContent, setShowContent] = useState(false);
  const { user } = useUserStore();

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div className={styles.container}>
      <div className={styles.welcomeBox}>
        <div className={styles.welcomeText}>
          {`${user.first_name}, get ready to skyrocket your business`}
        </div>
        <div className={styles.videoBtn}>
          <button className={styles.buttonWrapper} onClick={toggleContent}>
            {showContent ? (
              <>Hide</>
            ) : (
              <>
                learn <Camera />
              </>
            )}
          </button>
        </div>
      </div>
      {showContent && (
        <div className={styles.additionalContent}>
          <div className={styles.card}>How to use the AI Copywriting</div>
          <div className={styles.card}>Tour Guide on Nyle Platform</div>
          <div className={styles.card}>
            How to get the Best Results with Nyle
          </div>
          <div className={styles.card}>Case Studies with Our Clients</div>
        </div>
      )}
    </div>
  );
};
