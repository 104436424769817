import "./index.css";
import {
  IDashboardState,
  useDashboardStore,
} from "@pages/Dashboard/store/dashboard.state.ts";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import { Header } from "./Header";

export const Widgets = () => {
  const widgets = useDashboardStore((state: IDashboardState) => state.widgets);

  const widgetsJSX = widgets.map((w: IWidget) => {
    return <Widget key={w.id} widget={w} />;
  });

  return (
    <div className="dashboard-widgets">
      <Header />
      <div className="dashboard-widgets__list">{widgetsJSX}</div>
    </div>
  );
};
