import "./index.scss";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCallback } from "react";
import { format } from "date-fns";
import { CustomTooltip } from "./CustomTooltip";
import { IProductPerformanceMetric } from "@pages/Dashboard/types/dashboard.types.ts";

export const Chart = () => {
  const day = 1000 * 3600 * 24;
  const data: IProductPerformanceMetric[] = [
    {
      date: new Date().getTime() - day * 6,
      totalSales: 2100,
      ntbSales: 2600,
      totalAdSales: 3100,
      totalAdSpend: 2000,
      subscriptionSales: 600,
      contributionProfit: 600,
    },
    {
      date: new Date().getTime() - day * 5,
      totalSales: 2400,
      ntbSales: 3300,
      totalAdSales: 2900,
      totalAdSpend: 2600,
      subscriptionSales: 1000,
      contributionProfit: 400,
    },
    {
      date: new Date().getTime() - day * 4,
      totalSales: 1960,
      ntbSales: 2400,
      totalAdSales: 3100,
      totalAdSpend: 1700,
      subscriptionSales: 400,
      contributionProfit: 800,
    },
    {
      date: new Date().getTime() - day * 3,
      totalSales: 2500,
      ntbSales: 3100,
      totalAdSales: 2300,
      totalAdSpend: 2100,
      subscriptionSales: 600,
      contributionProfit: 400,
    },
    {
      date: new Date().getTime() - day * 2,
      totalSales: 2750,
      ntbSales: 2900,
      totalAdSales: 2500,
      totalAdSpend: 1400,
      subscriptionSales: 500,
      contributionProfit: 2400,
    },
    {
      date: new Date().getTime() - day,
      totalSales: 2400,
      ntbSales: 3400,
      totalAdSales: 2800,
      totalAdSpend: 2600,
      subscriptionSales: 600,
      contributionProfit: 400,
    },
    {
      date: new Date().getTime(),
      totalSales: 2000,
      ntbSales: 3000,
      totalAdSales: 2600,
      totalAdSpend: 2100,
      subscriptionSales: 200,
      contributionProfit: 700,
    },
  ];

  const convertDate = useCallback((timestamp: number) => {
    return format(new Date(timestamp), "d MMM");
  }, []);

  const convertCurrency = useCallback((value: number) => {
    return `$${value}`;
  }, []);

  return (
    <div className="sales-performance__chart" style={{ zIndex: "0" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#1F1F20" />
          <XAxis
            dataKey="date"
            tickFormatter={convertDate}
            interval="preserveStartEnd"
            stroke="#7C7C78"
          />
          <YAxis
            type="number"
            stroke="#7C7C78"
            ticks={[0, 500, 1000, 1500, 2000, 2500, 3000, 3500]}
            tickFormatter={convertCurrency}
          />

          <defs>
            <linearGradient id="ntbSalesArea" x1="0" y1="0" x2="0" y2="1">
              <stop offset={0.1} stopColor="#1BB08D" stopOpacity={0.14} />
              <stop offset={0.6} stopColor="#1BB08D" stopOpacity={0} />
            </linearGradient>
          </defs>

          <Tooltip
            content={CustomTooltip}
            cursor={{
              fill: "transparent",
              stroke: "white",
              strokeDasharray: 4,
            }}
          />

          <Line
            type="monotone"
            dataKey="totalSales"
            stroke="#80C67A"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="ntbSales"
            stroke="#1BB08D"
            strokeWidth={2}
            dot={false}
          />
          <Area
            type="monotone"
            dataKey="ntbSales"
            stroke="url(#ntbSalesArea)"
            fill="url(#ntbSalesArea)"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="totalAdSales"
            stroke="#FFFFFF"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="totalAdSpend"
            stroke="#6B4DBA"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="contributionProfit"
            stroke="#5295E0"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="subscriptionSales"
            stroke="#C8A857"
            strokeWidth={2}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
