import { Navigate, RouteObject } from "react-router-dom";
import { ROUTES } from "./routes";
import { Auth } from "@pages/Auth/Auth";
import { PrivatePage } from "@pages/PrivatePage/PrivatePage";
import { Login } from "@pages/Auth/Login/Login";
import { Signup } from "@pages/Auth/Signup/Signup";
import { VerifyProvider } from "@pages/Auth/VerifyProvider";
import { AmazonOnboarding } from "@pages/AmazonOnboarding/AmazonOnboarding.tsx";
import { Dashboard } from "@pages/Dashboard";
import { DocumentPage } from "@pages/document/DocumentPage.tsx";
import FreeFormWriting from "@pages/free-form-writing/FreeFormWriting.tsx";
import { SettingsPage } from "@pages/settings";
import { AiTemplates } from "@pages/ai-templates";
import { ImageEnhancePage } from "@pages/ImageEnhancePage";
import { RemoveBackgroundPage } from "@pages/RemoveBackgroundPage";
import { NotFoundPage } from "@pages/NotFoundPage";
import { AmazonOnboardingComplete } from "@pages/AmazonOnboardingComplete/AmazonOnboardingComplete.tsx";
import { Overview } from "@pages/Overview";
import SuccessfulRegistration from "@pages/Auth/Signup/SuccessfulRegistration";
import ForgotPassword from "@pages/Auth/Login/ForgotPassword";
import Activate from "@pages/Activate/Activate";
import ConfirmedEmail from "@pages/СonfirmedEmail";
import { ConfirmPasswordReset } from "@pages/Auth/СonfirmPasswordReset";
import ConfirmedPassword from "@pages/ConfirmedPassword";

export const routes: RouteObject[] = [
  {
    path: `${ROUTES.AUTH}/*`,
    element: <Auth />,
    children: [
      {
        path: "",
        element: <Navigate to={ROUTES.AUTH_LOGIN} />,
      },
      {
        path: ROUTES.AUTH_LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.AUTH_FORGOT,
        element: <ForgotPassword />,
      },
      {
        path: ROUTES.AUTH_SIGNUP,
        element: <Signup />,
      },
      {
        path: ROUTES.AUTH_SIGNUPSUCCESSFUL,
        element: <SuccessfulRegistration />,
      },
      {
        path: ROUTES.AUTH_CALLBACK,
        element: <VerifyProvider />,
      },
    ],
  },
  {
    path: `${ROUTES.AUTH_ACTIVATE}/*`,
    element: <Activate />,
  },
  {
    path: `${ROUTES.AUTH_CONFIRMED}/*`,
    element: <ConfirmedEmail />,
  },
  {
    path: `${ROUTES.AUTH_CONFIRMED}/*`,
    element: <ConfirmedEmail />,
  },
  {
    path: `${ROUTES.AUTH_PASSWORDCONFIRMED}/*`,
    element: <ConfirmedPassword />,
  },
  {
    path: `${ROUTES.AUTH_PASSWORDRESET}/:uid/:token`,
    element: <ConfirmPasswordReset />,
  },
  {
    path: "*",
    element: <PrivatePage />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "overview",
        element: <Overview />,
      },
      {
        path: ROUTES.AMAZON_ONBOARDING,
        element: <AmazonOnboarding />,
      },
      {
        path: ROUTES.AMAZON_ONBOARDING_COMPLETE,
        element: <AmazonOnboardingComplete />,
      },
      {
        path: "documents",
        element: <DocumentPage />,
      },
      {
        path: "free-form-writing/*",
        element: <FreeFormWriting />,
        children: [
          {
            path: ":documentId",
            element: <FreeFormWriting />,
          },
        ],
      },
      {
        path: "settings/*",
        element: <SettingsPage />,
      },
      {
        path: "ai-templates/*",
        element: <AiTemplates />,
      },
      {
        path: "image-enhance/*",
        element: <ImageEnhancePage />,
      },
      {
        path: "remove-bg/*",
        element: <RemoveBackgroundPage />,
      },
      {
        path: "404",
        element: <NotFoundPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];
