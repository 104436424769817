import Axios from "axios";
import { useAuthStore } from "src/store/auth.state";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || "";

class RefreshTokenService {
  public static async refresh(refresh_token: string): Promise<string> {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/token/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: refresh_token }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          useAuthStore.getState().logout();
        }
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      const { access } = data;

      localStorage.setItem("access_token", access);
      return access;
    } catch (error) {
      useAuthStore.getState().logout();
      throw error;
    }
  }

  public static async isTokenActive(token: string): Promise<boolean> {
    try {
      await Axios.post("/api/v1/token/verify", {
        token: token,
      });
      return true;
    } catch (error) {
      if (
        Axios.isAxiosError(error) &&
        (error.response?.status === 401 || error.response?.status === 403)
      ) {
        useAuthStore.getState().logout();
      }
      return false;
    }
  }
}

export default RefreshTokenService;
