import { useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Legend } from "@pages/Dashboard/features/SalesPerformance/Legend";
import { Chart } from "@pages/Dashboard/features/SalesPerformance/Chart";
import MetricModal from "./MetricModal";

export const SalesPerformance = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="sales-performance">
      <header className="sales-performance__header">
        <h3 className="sales-performance__title heading-2">
          {t("label.sales_performance")}
        </h3>
      </header>

      <div className="sales-performance__body">
        <Legend handleOpenModal={handleOpenModal} />
        <Chart />
      </div>

      <MetricModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};
