import React, { useState } from "react";
import styles from "./styles.module.scss";
import Openproblem from "@assets/icons/openproblem";
import CloseProblem from "@assets/icons/closeProblem";
import { ProblemDetails } from "./ProblemDetails";

interface ProblemsBlockProps {
  topProblems: Problem[];
}

interface Problem {
  title: string;
  description: string;
}

const ProblemsBlock: React.FC<ProblemsBlockProps> = ({ topProblems }) => {
  const [selectedProblem, setSelectedProblem] = useState<Problem | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleProblemClick = (problem: Problem, index: number) => {
    if (selectedProblem?.title === problem.title) {
      setSelectedProblem(null);
      setSelectedIndex(null);
    } else {
      setSelectedProblem(problem);
      setSelectedIndex(index);
    }
  };

  return (
    <div className={styles.expandedContainer}>
      <div className={styles.expandedContent}>
        <h3>Top 5 Problems</h3>
        <div className={styles.problemsGrid}>
          {topProblems.map((problem, i) => (
            <div
              key={i}
              className={`${styles.problem} ${selectedIndex === i ? styles.expandedProblem : ""}`}
              onClick={() => handleProblemClick(problem, i)}
            >
              <div>
                <h4>{problem.title}</h4>
                {selectedProblem?.title === problem.title ? (
                  <CloseProblem />
                ) : (
                  <Openproblem />
                )}
              </div>
              <p>{problem.description}</p>
              <div className={styles.leftBorder}></div>
              <div className={styles.rightBorder}></div>
            </div>
          ))}
        </div>
        {selectedProblem && (
          <div className={styles.problemMoreInfo}>
            <ProblemDetails problem={selectedProblem} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProblemsBlock;
