import { ICountry } from "src/types/country.types.ts";

export enum AuthProvider {
  Google = "google-oauth2",
  AmazonSellerAccount = "amazon-seller-account",
  Facebook = "facebook",
}

export enum Environment {
  LOCAL = "local",
  DEV = "dev",
  STAGING = "staging",
  PROD = "prod",
}

export enum AmazonOnboardingStatus {
  Started = "started",
  Completed = "completed",
}

export const DEFAULT_AMAZON_SELLER_COUNTRY: ICountry = {
  code: "US",
  name: "United States of America",
};
