import styles from "src/pages/Auth/Auth.module.scss";
import Logo from "@assets/img/logo.tsx";
import { AuthIntro } from "@pages/Auth/AuthIntro";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import { IAuthState, useAuthStore } from "src/store/auth.state";
import { LS } from "src/constants/localStorage.constants.ts";
import {
  AmazonOnboardingStatus,
  AuthProvider,
} from "src/constants/auth.constants.ts";
import { useMemo } from "react";

export const Auth = () => {
  const authenticated = useAuthStore(
    (state: IAuthState) => state.authenticated,
  );
  const showSidePanel = useMemo<boolean>(() => {
    const provider = localStorage.getItem(LS.AuthProvider);
    return provider !== AuthProvider.AmazonSellerAccount;
  }, []);

  const location = useLocation();

  if (authenticated && location.pathname !== ROUTES.AUTH_PASSWORDRESET) {
    const amazonOnboardingStatus = localStorage.getItem(
      LS.AmazonOnboardingStatus,
    );
    const amazonOnboardingCompleted =
      amazonOnboardingStatus === AmazonOnboardingStatus.Completed ||
      !amazonOnboardingStatus;

    if (amazonOnboardingCompleted) {
      return <Navigate to={ROUTES.ROOT} />;
    }
  }

  return (
    <div className={styles.authPage}>
      {showSidePanel && <AuthIntro />}
      <div className={styles.authPageMain}>
        <div>
          <Logo width="67" height="30" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};
