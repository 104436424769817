import { SALES_PERFORMANCE_METRICS } from "../../constants";
import { useMemo } from "react";

export const CustomTooltip = (props: any) => {
  const { active, payload } = props;

  if (!active) {
    return null;
  }

  const uniquePayload = useMemo(() => {
    const visited = {};
    const result = [];

    payload.forEach((p) => {
      if (visited[p.name] === undefined) {
        visited[p.name] = true;
        result.push(p);
      }
    });

    return result;
  }, [payload]);

  const payloadJSX = uniquePayload.map((p: any, i: number) => {
    return (
      <div key={i} className="performance-tooltip__row">
        <div className="performance-tooltip__rowLabel body-small">
          {SALES_PERFORMANCE_METRICS[p.name]}
        </div>
        <div className="performance-tooltip__rowValue body-small">
          ${p.value}
        </div>
      </div>
    );
  });

  return <div className="performance-tooltip">{payloadJSX}</div>;
};
