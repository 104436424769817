import React from "react";
import styles from "./styles.module.scss";
import UserPopupArrow from "@assets/icons/userPopupArrow";

interface SettingsPopupProps {}

export const SettingsPopup: React.FC<SettingsPopupProps> = () => {
  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <ul>
          <li>
            Settings <UserPopupArrow />
          </li>
          <li>
            Plans & Billing <UserPopupArrow />
          </li>
          <li>
            Connections <UserPopupArrow />
          </li>
          <li>
            Notification Settings <UserPopupArrow />
          </li>
          <li>
            Become an Affiliate <UserPopupArrow />
          </li>
        </ul>
      </div>
    </div>
  );
};
