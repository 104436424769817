export enum ROUTES {
  ROOT = "/",
  OVERVIEW = "/overview",
  FINANCE = "/finance",
  AUTH_ACTIVATE = "activate/:uid/:token",
  AUTH = "/auth",
  AUTH_LOGIN = "login",
  AUTH_FORGOT = "forgot",
  AUTH_SIGNUP = "signup",
  AUTH_CONFIRMED = "/confirmed",
  AUTH_PASSWORDCONFIRMED = "/password-confirmed",
  AUTH_SIGNUPSUCCESSFUL = "successful-registration",
  AUTH_PASSWORDRESET = "/password/reset/confirm",
  AUTH_CALLBACK = "callback",
  AMAZON_ONBOARDING = "amazon-onboarding",
  AMAZON_ONBOARDING_COMPLETE = "amazon-onboarding-complete",
  SETTINGS_ACCOUNT = "/settings/account",
  SETTINGS_PLAN = "/settings/plan",
  SETTINGS_TEAM = "/settings/team",
}
