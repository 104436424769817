import Axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { useAuthStore } from "src/store/auth.state";
import RefreshTokenService from "@services/refreshToken";

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error("Request error:", error);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<any> => {
  console.error("Response error:", error);
  if (Axios.isAxiosError(error)) {
    const isUnauthorized =
      error.response?.status === 401 || error.response?.status === 403;
    const originalRequest = error.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };

    if (isUnauthorized && !originalRequest._retry) {
      const refresh_token = localStorage.getItem("refresh_token");
      if (!refresh_token) {
        useAuthStore.getState().logout();
        return Promise.reject(error);
      }

      originalRequest._retry = true;

      try {
        const access = await RefreshTokenService.refresh(refresh_token);
        originalRequest.headers["Authorization"] = `Bearer ${access}`;
        return Axios(originalRequest);
      } catch (refreshError) {
        if (
          Axios.isAxiosError(refreshError) &&
          refreshError.response?.status === 401
        ) {
          useAuthStore.getState().logout();
        }
        return Promise.reject(refreshError);
      }
    } else if (isUnauthorized) {
      useAuthStore.getState().logout();
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
};

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || "";

export const intercept = (): void => {
  Axios.defaults.baseURL = API_BASE_URL;
  Axios.defaults.withCredentials = true;
  Axios.interceptors.request.use(onRequest, onRequestError);
  Axios.interceptors.response.use(onResponse, onResponseError);
};
