import React from "react";
import "./index.css";

interface LegendProps {
  handleOpenModal: () => void;
}

export const Legend: React.FC<LegendProps> = ({ handleOpenModal }) => {
  return (
    <div className="sales-performance__legend">
      <div className="sales-performance__legendGroup">
        <div className="sales-performance__legendItem">
          <div className="sales-performance__legendCircle green" />
          <div className="sales-performance__legendLabel body-large bold">
            Total Sales
          </div>
        </div>

        <div className="sales-performance__legendItem">
          <div className="sales-performance__legendCircle turquoise" />
          <div className="sales-performance__legendLabel body-large bold">
            NTB Sales
          </div>
        </div>

        <div className="sales-performance__legendItem">
          <div className="sales-performance__legendCircle white" />
          <div className="sales-performance__legendLabel body-large bold">
            Total Ad Sales
          </div>
        </div>
      </div>

      <div className="sales-performance__legendGroup">
        <div className="sales-performance__legendItem">
          <div className="sales-performance__legendCircle violet" />
          <div className="sales-performance__legendLabel body-large">
            Total Ad Spend
          </div>
        </div>

        <div className="sales-performance__legendItem">
          <div className="sales-performance__legendCircle darkYellow" />
          <div className="sales-performance__legendLabel body-large">
            Subscription Sales
          </div>
        </div>

        <div className="sales-performance__legendItem">
          <div className="sales-performance__legendCircle blue" />
          <div className="sales-performance__legendLabel body-large">
            Contribution Profit
          </div>
        </div>
      </div>
      <button className="sales-performance__button" onClick={handleOpenModal}>
        Metrics
      </button>
    </div>
  );
};
