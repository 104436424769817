import React, { useEffect, useState } from "react";
import CustomCheckbox from "@components/CustomCheckbox";
import { mockMetrics } from "@pages/Dashboard/mocks/mockMetricsData";
import InfoIcon from "@assets/icons/i.svg";
import ModalBox from "@components/ModalBox";
import styles from "./styles.module.scss";

interface Metric {
  name: string;
  checked: boolean;
}

interface MetricModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MetricModal: React.FC<MetricModalProps> = ({ isOpen, onClose }) => {
  const [metrics, setMetrics] = useState<Metric[]>(mockMetrics);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleCheckboxChange = (index: number) => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric, i) =>
        i === index ? { ...metric, checked: !metric.checked } : metric,
      ),
    );
  };

  const handleReset = () => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric) => ({ ...metric, checked: false })),
    );
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} title="Choose Metrics">
      <p className={styles.modalDescription}>
        Choose which metrics you want to show in the «Sales Performance»
      </p>
      <div className={styles.modalMetricsOptions}>
        {metrics.map((metric, index) => (
          <label key={index} className={styles.metricLabel}>
            <CustomCheckbox
              checked={metric.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            {metric.name}
            <img src={InfoIcon} alt="Info" className={styles.infoIcon} />
          </label>
        ))}
      </div>
      <div className={styles.modalActions}>
        <button className={styles.modalButton} onClick={handleReset}>
          Reset to default
        </button>
        <button className={styles.modalButtonApply} onClick={onClose}>
          Apply
        </button>
      </div>
    </ModalBox>
  );
};

export default MetricModal;
