import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import UserPopupArrow from "@assets/icons/userPopupArrow";
import Photo from "@assets/img/Photo.png";
import Photo2 from "@assets/img/Photo2.png";
import Photo3 from "@assets/img/Photo3.png";
import Photo4 from "@assets/img/Photo4.png";
import { useAuthStore } from "src/store/auth.state";

interface UserPopupProps {
  closeMenu: () => void;
}

interface User {
  name: string;
  title: string;
  photo: string;
}

const users: User[] = [
  { name: "John Gold", title: "CEO", photo: Photo },
  { name: "John Gold", title: "CMO", photo: Photo2 },
  { name: "John Gold", title: "COO", photo: Photo3 },
  { name: "John Gold", title: "CFO", photo: Photo4 },
];

export const UserPopup: React.FC<UserPopupProps> = ({ closeMenu }) => {
  const [activeUser, setActiveUser] = useState<User>(users[0]);
  const navigate = useNavigate();
  const { logout } = useAuthStore();

  const handleNavigation = (path: string) => {
    navigate(path);
    closeMenu();
  };

  const handleLogout = () => {
    logout();
    closeMenu();
  };

  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <div className={styles.section}>
          <div
            className={styles.menuItem}
            onClick={() => handleNavigation("/referral-page")}
          >
            <span>Referral page</span>
            <UserPopupArrow />
          </div>
          <div
            className={styles.menuItem}
            onClick={() => handleNavigation("/settings/account")}
          >
            <span>Settings</span>
            <UserPopupArrow />
          </div>
          <div
            className={styles.menuItem}
            onClick={() => handleNavigation("/settings/plan")}
          >
            <span>Plans & Billing</span>
            <UserPopupArrow />
          </div>
          <div
            className={styles.menuItem}
            onClick={() => handleNavigation("/connections")}
          >
            <span>Connections</span>
            <UserPopupArrow />
          </div>
          <div
            className={styles.menuItem}
            onClick={() => handleNavigation("/notification-settings")}
          >
            <span>Notification Settings</span>
            <UserPopupArrow />
          </div>
        </div>
        <div className={styles.profileSection}>
          {users.map((user, index) => (
            <div
              key={index}
              className={`${styles.profileItem} ${
                activeUser.name === user.name && activeUser.title === user.title
                  ? styles.activeUser
                  : ""
              }`}
              onClick={() => setActiveUser(user)}
            >
              <img
                src={user.photo}
                alt={user.name}
                className={styles.profileImage}
              />
              <div className={styles.profileDetails}>
                <span className={styles.profileName}>{user.name}</span>
                <span className={styles.profileTitle}>{user.title}</span>
              </div>
              <input
                type="radio"
                name="profile"
                checked={
                  activeUser.name === user.name &&
                  activeUser.title === user.title
                }
                readOnly
                className={styles.radioButton}
              />
            </div>
          ))}
        </div>
        <div className={styles.logoutSection}>
          <button className={styles.logoutBtn} onClick={handleLogout}>
            <span> Log Out </span>
            <UserPopupArrow />
          </button>
        </div>
      </div>
    </div>
  );
};
